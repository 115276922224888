<template>
  <div class="user-profile-info">
    <div class="back">
      <span @click="back">
        <svg
          t="1645171851221"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          p-id="81479"
          width="30"
          height="30"
        >
          <path
            d="M475 276V141.4c-12.1-56.3-58.2-22-58.2-22L96.6 395.9c-70.4 48.9-4.8 85.7-4.8 85.7l315.4 274.1c63.1 46.5 67.9-24.5 67.9-24.5V606.4C795.3 506 926.3 907.5 926.3 907.5c12.1 22 19.4 0 19.4 0C1069.4 305.4 475 276 475 276z"
            p-id="81480"
            fill="#5a5f69"
          ></path>
        </svg>
      </span>
      <div>用户画像</div>
      <div @click="searchUserStatus = true">
        <svg
          t="1730185015904"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2988"
          width="26"
          height="26"
        >
          <path
            d="M797.525333 752.266667c62.069333-72.736 97.28-165.002667 97.28-262.186667C894.816 266.528 713.621333 85.333333 490.08 85.333333 266.538667 85.333333 85.333333 266.538667 85.333333 490.069333 85.333333 713.610667 266.538667 894.826667 490.069333 894.826667a404.693333 404.693333 0 0 0 118.208-17.546667 32 32 0 0 0-18.666666-61.216 340.693333 340.693333 0 0 1-99.541334 14.762667C301.888 830.816 149.333333 678.261333 149.333333 490.069333 149.333333 301.888 301.888 149.333333 490.069333 149.333333 678.261333 149.333333 830.826667 301.888 830.826667 490.069333c0 89.28-35.381333 173.696-97.141334 237.322667a36.992 36.992 0 0 0 0.384 51.925333l149.973334 149.973334a32 32 0 0 0 45.258666-45.248L797.525333 752.266667z"
            fill="#000000"
            p-id="2989"
          ></path>
        </svg>
      </div>
    </div>
    <div class="user-profile-content">
      <div class="info-section">
        <h3>基本信息</h3>
        <div class="info-list">
          <p><strong>姓名:</strong> {{ userInfo.name }}</p>
          <p><strong>年龄:</strong> {{ userInfo.age }}</p>
          <p><strong>单位:</strong> {{ userInfo.tj_deptName_3rd }}</p>
          <p><strong>部门:</strong> {{ userInfo.tj_deptName_4th }}</p>
        </div>
      </div>
      <div>
        <h3 style="text-align: left">画像标签:</h3>
        <div class="user-portrait">
          <div
            class="portrait"
            ref="charts"
            style="width: 160px; height: 140px"
          >
            <!-- <img src="../../src/assets/data.png" alt="" /> -->
          </div>
          <div class="label">
            <p
              :style="{ background: getRandomColor() }"
              v-for="(tag, index) in userInfo.tagArr"
              :key="index"
            >
              {{ tag }}
            </p>
          </div>
        </div>
      </div>
      <div class="info-section">
        <!-- <p><strong>点赞:</strong> {{ userInfo.likes }}</p> -->
        <div>
          <h3>喜欢的栏目:</h3>
          <div class="favorite-list">
            <div v-for="(item, index) in userInfo.top_ljObj" :key="index">
              <div>
                <span> {{ index + 1 }}</span>
                <p>{{ item.name }}</p>
              </div>
              <span>{{ item.readNum }} 次</span>
            </div>
          </div>
        </div>
        <div>
          <h3>喜欢的文章:</h3>
          <div class="favorite-list">
            <div v-for="(item, index) in userInfo.top_contentData" :key="index">
              <div>
                <span> {{ index + 1 }}</span>
                <p>{{ item.title }}</p>
              </div>
            </div>
          </div>
        </div>

        <div ref="chart" style="width: 100%; height: 400px"></div>
      </div>
    </div>
    <div v-show="searchUserStatus">
      <user-list
        @searchBack="searchUserStatus = false"
        @chooseItem="chooseItem"
      >
      </user-list>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { index } from "cheerio/lib/api/traversing";
import { wxConPost, statsPost } from "../api/httpApi";
import commonTop from "../components/commonTop.vue";
import wxLoginApi from "../api/wxLoginApi";
import userList from "../components/userList.vue";


export default {
  props: {

  },
  data () {
    return {
      searchUserStatus: false,
      wxUser: {
        userid: ''
      },
      userInfo: {

      },
      userInfos: {
        name: '刘晓辉',
        station: '北京站',
        position: '工程师',
        age: 26,
        tags: ['阅读达人', '年轻白领', '科技爱好者', '点赞达人', '评论达人'],
        likes: 120,
        favoriteSections: ['Vue 入门指南', '如何保持健康', '旅行必备清单',],
        favoriteArticles: ['他把思想工作做到了职工的心坎上', '韦皓同志致广铁集团干部职工的一封信', '“大美广铁”风光无限，新栏目新起航！']
      },
      dataNum: [],
      radar: []
    };
  },
  computed: {

  },
  created () {
    // this.wxUser = wxLoginApi.getUser();


  },
  mounted () {

    wxLoginApi.onReady(async () => {
      try {
        this.wxUser = wxLoginApi.getUser();
        console.log(wxLoginApi.getUser(), 'id');
        setTimeout(() => {
          this.getUserData()
        }, 10);
      } catch (error) {

      }
    })








  },

  watch: {

  },
  methods: {
    chooseItem (wxUser) {

      this.wxUser.userid = wxUser.userid;
      this.searchUserStatus = false;
      this.getUserData();
    },
    async getUserData () {

      try {
        this.showLoad = true;
        const ret = await statsPost(
          "/huaxiang/find",
          {
            userid: this.wxUser.userid,
            // userid: "mengqi"
          }
        );
        //
        this.userInfo = ret.ret;
        const values = Object.entries(this.userInfo.actionData).reduce((acc, [key, value]) => {
          if (key !== "count") { // 排除不需要的键
            // const convertedValue = key === "readTime" ? value / 60 : value;
            acc.push(value);
          }
          return acc;
        }, []);

        this.dataNum = values;
        this.radar = this.userInfo.actionData
        this.initChart()
        this.initCharts()
      } catch (e) {

        this.$Notice.warning({
          title: "当前用户暂无数据",
        });
      }
    },
    //随机颜色
    getRandomColor () {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    back () {
      this.$router.push({ path: "/statsmain" });
    },
    initCharts () {
      const chart = echarts.init(this.$refs.charts);

      const option = {


        radar: {

          indicator: [
            { name: '阅读', max: 100 },
            { name: '时长', max: 1000 },
            { name: '点赞', max: 100 },
            { name: '分享', max: 100 },
            { name: '评论', max: 100 },
          ],
          // indicator: this.dataNum,
          name: {
            textStyle: {
              color: '#3333FF' // 设置雷达图指标名称的字体颜色
            }
          },
          axisLine: {
            lineStyle: {
              color: '#3333FF' // 设置雷达图轴线的颜色
            }
          },
          splitLine: {
            lineStyle: {
              color: '#3333FF' // 设置雷达图分割线的颜色
            }
          },
          radius: '60%'
        },
        series: [
          {

            type: 'radar',
            data: [
              {
                value: this.dataNum,

              },

            ],
            itemStyle: {
              color: '#FFCE33' // 设置雷达图数据点的颜色
            }
          }
        ]
      };

      chart.setOption(option);
    },
    initChart () {
      // 初始化 ECharts 实例
      const chart = echarts.init(this.$refs.chart);
      var arr = []
      arr = this.dataNum
      // 配置项
      const option = {
        title: {
          text: '用户行为',
          textStyle: {
            color: '#3f51b5',
            fontSize: '16px'
          },
          left: 'center'
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: ['阅读', '时长', '点赞', '分享', '评论'],
          axisLabel: {
            color: '#3f51b5' // 设置 x 轴标签字体颜色
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#3f51b5' // 设置 x 轴标签字体颜色
          }
        },
        series: [
          {
            name: '次数',
            type: 'bar',
            data: this.dataNum,
            itemStyle: {
              color: '#3f51b5' // 设置柱状图颜色
            }
          }
        ]
      };

      // 使用配置项生成图表
      chart.setOption(option);
    }
  },
  components: {
    commonTop, userList,
  },
};
</script>

<style scoped >
.back {
  padding: 3px 10px;
  width: 100%;
  height: 44px;
  display: flex;
  background: white;
  font-weight: bold;
  /* line-height: 44px; */
  display: flex;
  position: relative;
  text-align: center;
  color: #5a5f69;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  justify-content: space-between;
  align-items: center;
}
.user-profile-content {
  padding: 0 17px;
}
.user-profile-info {
  color: #3f51b5;
  /* background: #eafcff; */
  /* background: url("../../src/assets/kg-bgi.png"); */
  /* background-size: 100% 100%;
  background-repeat: no-repeat; */
  text-align: center;
}
.info-list {
  padding: 5px 10px;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  /* border: 1px solid #7dbdff; */
  box-shadow: #03a9f48c 0px 2px 4px, #2196f347 0px 7px 13px -3px,
    rgb(33 150 243 / 11%) 0px -3px 0px inset;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.info-list p {
  margin: 5px 0;
  width: 50%;
}
.info-section {
  margin-top: 15px;
  text-align: left;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.tag {
  background-color: #42b983;
  color: white;
  padding: 4px 6px;
  margin: 5px;
  border-radius: 20px;
  font-size: 14px;
}
.favorite-list {
  /* border: 1px solid #7dbdff; */
  box-shadow: #03a9f48c 0px 2px 4px, #2196f347 0px 7px 13px -3px,
    rgb(33 150 243 / 11%) 0px -3px 0px inset;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  margin: 10px 0;
  padding: 5px 10px;
  width: 100%;
}
.favorite-list div {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.favorite-list div:first-child span {
  color: red;
  font-weight: bold;
  font-size: 16px;
}
.favorite-list div:nth-child(2) span {
  color: orange;
  font-weight: bold;
  font-size: 16px;
}
.favorite-list div:nth-child(3) span {
  color: green;
  font-weight: bold;
  font-size: 16px;
}
.favorite-list p {
  margin-left: 10px;
}
.user-portrait {
  position: relative;
  margin: 10px 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 150px;
  justify-content: space-around;
  /* border: 1px solid #7dbdff; */
  box-shadow: #03a9f48c 0px 2px 4px, #2196f347 0px 7px 13px -3px,
    rgb(33 150 243 / 11%) 0px -3px 0px inset;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.portrait {
  width: 150px;
  height: 150px;
}
.portrait img {
  width: 150px;
  height: 150px;
}
.user-portrait .label {
  width: 45%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}
.user-portrait .label p {
  background-color: #42b983;
  color: white;
  height: 30px;
  padding: 0 5px;
  line-height: 30px;
  animation: moveUpDown 2s ease-in-out infinite;
  border-radius: 10px;
  margin: 5px;
  font-size: 14px;
}

@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0); /* 初始和结束位置 */
  }
  50% {
    transform: translateY(5px); /* 中间位置 */
  }
}
</style>
