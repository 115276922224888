<style scoped>
.list {
  display: flex;
  text-align: left;
  padding: 0 10px;
  margin: 10px 0;
}
.content {
  width: 80%;
}
.title {
  padding: 5px 0;
  font-size: 16px;
  display: block;
  width: 20%;
}
.userInfoinput {
  border: 1px solid #f1eeee;
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  line-height: 2rem;
  border-radius: 5px;
  color: #695f5f;
  font-weight: bold;
  padding: 0 10px;
  outline: none;
}
.btnClass {
  margin: 10px 0;
}
</style>
<template>
  <div>
    <div style="padding-bottom: 10px">
      <div class="list">
        <span class="title">标题:</span>
        <div class="content">
          <input
            class="userInfoinput"
            type="text"
            placeholder="请输入..."
            v-model="editObj.title"
          />
        </div>
      </div>
      <div class="list">
        <span class="title">摘要:</span>
        <div class="content">
          <input
            class="userInfoinput"
            type="text"
            placeholder="请输入..."
            v-model="editObj.brief"
          />
        </div>
      </div>
      <div class="list">
        <span class="title">栏目:</span>
        <Select class="content" v-model="editObj.column[0]">
          <Option
            v-for="item in cloumnList"
            :value="editObj.column[0]"
            :key="item"
            >{{ item }}</Option
          >
        </Select>
      </div>
      <div class="list">
        <span class="title">关键字1:</span>
        <div class="content">
          <input
            class="userInfoinput"
            type="text"
            placeholder="请输入..."
            v-model="keyword1"
          />
        </div>
      </div>
      <div class="list">
        <span class="title">关键字2:</span>
        <div class="content">
          <input
            class="userInfoinput"
            type="text"
            placeholder="请输入..."
            v-model="keyword2"
          />
        </div>
      </div>
      <div class="list">
        <span class="title">关键字3:</span>
        <div class="content">
          <input
            class="userInfoinput"
            type="text"
            placeholder="请输入..."
            v-model="keyword3"
          />
        </div>
      </div>
      <div class="list" style="margin: 5px 0">
        <span class="title">原创:</span>
        <Button
          @click="checkWaterMarker"
          style="width: 30px; height: 30px"
          :type="editObj.isUnOriginal === true ? 'default' : 'primary'"
          shape="circle"
          icon="ios-star"
        ></Button>
      </div>
    </div>
    <vue-ueditor-wrap
      v-model="data"
      editor-id="editor"
      :config="editorConfig"
      ref="editorRef"
      :editorDependencies="['ueditor.config.js', 'ueditor.all.js']"
    >
    </vue-ueditor-wrap>
    <div class="btnClass">
      <Button type="info" @click="save" :disabled="showLoad">保存草稿</Button>
      <Button
        style="margin-left: 10px"
        @click="publishReview"
        :disabled="editObj.review !== '2'"
        :type="editObj.review !== '2' ? 'default' : 'info'"
        >提交审核</Button
      >
    </div>
  </div>
</template>

<script>
import VueUeditorWrap from "vue-ueditor-wrap";
import { wxConPost, getUserPm } from "../../api/httpApi";
export default {
  name: "pageEditor",
  data () {
    return {
      showLoad: false,
      data: "",
      keyword1: "", // 关键字1
      keyword2: "", // 关键字2
      keyword3: "", // 关键字3
      cloumnList: [
        "资讯",
        "组织",
        "视点",
        "宣传",
        "T视频",
        "时评",
        "健康体检",
        "集团信息",
        "青春广铁",
      ],
      editorConfig: {
        UEDITOR_HOME_URL: "/ueditor/",
        zIndex: 99, //设置z轴顺序值，避免工具栏下拉组件被遮挡
      },
      editObj: {},
    };
  },
  components: {
    VueUeditorWrap,
  },
  methods: {
    publishReview () { },
    save () { },
    cloumnSelect () { },
    checkWaterMarker () {
      if (_.isBoolean(this.editObj.isUnOriginal)) {
        this.$set(this.editObj, "isUnOriginal", !this.editObj.isUnOriginal);
      } else {
        this.$set(this.editObj, "isUnOriginal", true);
      }
    },
  },
  mounted () { },
  async created () {
    const cid = this.$route.query.cid;
    const ret = await wxConPost("/services/gttt-content-mgt/zd/getDetail", {
      _cid: cid,
    });
    this.editObj = ret.ret;
    let htmlData = this.editObj.content;
    if (htmlData.indexOf("/static/gtttueditor/newresource") > -1) {
      const reg = new RegExp("/static/gtttueditor/newresource", "g");
      htmlData = htmlData.replace(
        reg,
        "https://mgt.gtrmt.cn/static/gtttueditor/newresource"
      );
    }
    if (this.editObj.keywords) {
      this.keyword1 = this.editObj.keywords[0] ? this.editObj.keywords[0] : "";
      this.keyword2 = this.editObj.keywords[1] ? this.editObj.keywords[1] : "";
      this.keyword3 = this.editObj.keywords[2] ? this.editObj.keywords[2] : "";
    } else {
      this.keyword1 = "";
      this.keyword2 = "";
      this.keyword3 = "";
    }
    this.data = htmlData;
  },
};
</script>

<style>
</style>
